import * as React from "react"
import { Link } from "gatsby"
import { FaGithub, FaLinkedin, FaStackOverflow, FaTwitter, FaMastodon, FaGitlab, 
 } from "react-icons/fa";
import { FaBluesky } from "react-icons/fa6";
import { BsSubstack } from "react-icons/bs";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer className="footer">
          <a className="mr-2" href="https://jobsforai.substack.com/" target="_blank" rel="noopener noreferrer">
            <BsSubstack />
          </a>
          <a className="mr-2" href="https://github.com/raunaqss" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a className="mr-2" href="https://gitlab.com/raunaqss" target="_blank" rel="noopener noreferrer">
            <FaGitlab />
          </a>
          <a className="mr-2" href="https://stackoverflow.com/users/1468573/raunaqss" target="_blank" rel="noopener noreferrer">
            <FaStackOverflow />
          </a>
          <a className="mr-2" href="https://www.linkedin.com/in/raunaqss/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
          <a className="mr-2" href="https://twitter.com/raunaqss" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a className="mr-2" href="https://mastodon.social/@delic8darling" target="_blank" rel="noopener noreferrer">
            <FaMastodon />
          </a>
          <a className="mr-2" href="https://bsky.app/profile/raunaq.bsky.social" target="_blank" rel="noopener noreferrer">
            <FaBluesky />
          </a>
          <a href="https://www.are.na/raunaq-singh/channels" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', marginRight: '0.5rem' }}>
            **
          </a>
      </footer>
    </div>
  )
}

export default Layout
